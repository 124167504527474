$AccentColor: #019467;
$NeutralBlue: #97CCFF;
$AttentionYellow: #FFBA52;
$BlackToBlue: #0C1E5B;
$WarningREd: #FF837B;
$SuccessGreen: #9BDB5A;
$LightBlue: #EDF4FB;
$LightGrey: #FCFCFC;
$NotActiveGrey: #888888;
$Text: #333333;

$font: 'Nunito Sans', sans-serif;
$fontInter: 'Inter', sans-serif;

$padding-25: 25px;
$padding-20: 20px;
$padding-15: 15px;
$padding-10: 10px;
$padding-5: 5px;
