.mh-{
    &statusBadge {
        padding: 5px 10px 5px 5px;
        border-radius: 5px;
        background: #E0E0E0;
        font-size: 12px;
        color: #333333;
        display: inline-flex;
        align-items: center;
        height: 25px;

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 3.5px;
            background: #888888;
            margin-right: 5px;
        }

        &__publish {
            background: #C3FFDD;

            &::before {
                background: #019467;
            }
        }

        &__draft {
            background: #FFE5A1;

            &::before {
                background: #F2AE00;
            }
        }

        &__deleted {
            background: #FEEDEE;

            &::before {
                background: #FF837B;
            }
        }
    }
}