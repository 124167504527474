.mh-{
    &constructor {
        &_scale {
            display: flex;
            background: #ffffff;

            &Item {
                border: 1px solid #E0E0E0;
                padding: 10px;

                &:first-child {
                    border-radius: 5px 0px 0px 5px;
                }
    
                &:last-child {
                    border-radius: 0px 5px 5px 0px;
                }
    
                &:not(:last-child) {
                    border-right: 0px;
                }
            }
        }
    }
}