@import "variable";

.checkbox {
  cursor: pointer;
}

.input-field-search {
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  padding: 6.5px 17px;

  &__input {
    border: none !important;
    outline: none;
    padding: 0 0 0 10px !important;

    &::placeholder {
      font-family: $font;
      font-weight: 400;
      font-size: 13px;
      color: $Text;
    }
  }
}

.button {
  background-color: $AccentColor !important;
  padding: 6px 15px;
  border: 1px solid $AccentColor;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;

  font-family: $font;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;

  &--lg {
    width: auto;
    height: auto;
  }

  &__icon {
    display: inline-flex;
    margin-right: 10px;
  }
}

.select-count {
  &__output {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px 20px;

    & svg {
      margin-left: 20px;
    }
  }

  &__list {
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: #ffffff;
    list-style: none;
    padding: 0px;
    margin: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__item {
    font-family: $fontInter;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $Text;
    text-align: center;
    padding: 5px 30px;

    &:hover {
      background-color: $LightBlue;
      cursor: pointer;
    }
  }

  &__text {
    font-family: $font;
    font-weight: 300;
    font-size: 14px;
    color: $Text;
    display: inline-block;
    margin-left: 8px;
  }
}

.drop-down {
  position: relative;

  &__button {
    cursor: pointer;
  }

  &__list {
    position: absolute;
    top: 0;
    right: 20px;
    padding: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
    z-index: 10;
    background-color: #ffffff;
  }

  &__item {
    font-family: $font;
    font-weight: 300 !important;
    font-size: 14px;
    color: $Text !important;
    display: inline-block;
    padding: $padding-5 $padding-10;
    width: 100%;
    white-space: nowrap;

    &:hover {
      background-color: $LightBlue;
      cursor: pointer;
    }
  }
}

@media (max-width: 576px) {
  .drop-down {
    &__list {
      right: auto;
      left: 20px;
    }
  }
}
