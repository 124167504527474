/*tech/must delete*/

div#columns {
  grid-row-gap: 1em;
  display: grid;
}

/*start style*/

/*scrollbar*/

/* Firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: #757575 #dfe9eb;
}

/* Chrome, Edge and Safari */

*::-webkit-scrollbar {
  height: 10px;
  width: 7px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #757575;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #62a34b;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #62a34b;
}

/**/

:root {
  --neutral-blue: #97ccff;
  --accent-color: #019467;
  --attention-yellow: #ffba52;
  --warning-red: #ff837b;
  --success-green: #9bdb5a;
  --light-blue: #edf4fb;
  --light-grey: #fcfcfc;
  --not-active-grey: #888888;
  --border-round: 10px;
  --allowed-color-0: #b68c3b;
  --allowed-color-1: #1690a8;
  --allowed-color-2: #7b7a6e;
  --allowed-color-3: #607d8b;
  --allowed-color-4: #956c76;
  --allowed-color-5: #808d66;
  --allowed-color-6: #ae9564;
  --allowed-color-7: #a7ae64;
  --allowed-color-8: #9664ae;
  --allowed-color-9: #ae6464;
}

body {
  background: #f6f6f6 !important;
  color: #333;
}

a.button {
  display: flex;
  padding: 0;
  background-color: transparent !important;
  border: none !important;
}

.page--content {
  flex-grow: 1;
}

h2 {
  font-size: 1.5em !important;
  font-weight: 700 !important;
  padding: 0 !important;
}

div#menu-container {
  background: #fff;
}

aside.aside {
  border-right: 1px solid #eee;
  top: 0;
  position: sticky;
  z-index: 2;
}

div#offcanvasNavbar-expand-md {
  width: max-content;
}

.page--routs {
  padding: 1em;
}

/* .row {
    margin: 0;
} */

.hstack {
  align-items: normal;
}

.popover--head {
  padding: 10px 0 5px 0 !important;
}

.navigation--list {
  gap: 0 !important;
}

.popover--wrapper {
  padding-bottom: 5px !important;
}

.popover--sublist {
  gap: 5px !important;
}

img.popover--head--arrow {
  padding-left: 10px;
}

div#page-content {
  background: #fff;
  padding: 1.5em;
  border-radius: var(--border-round);
}

div#main-page-block {
  padding: 0;
  grid-row-gap: 1em;
  display: flex;
  flex-direction: column;
}

span.require {
  color: red;
}

header.header {
  height: auto;
  box-shadow: 0 0px 20px 0 #d0d0d042;
  top: 0;
  width: 100%;
  position: sticky;
  padding: 0.5em 1em;
  z-index: 1;
}

div#main-header {
  background: #fff;
  border-left: 1px solid #eee;
  padding: 5px 10px;
}

div#page-container {
  display: grid;
  grid-row-gap: 1em;
  height: max-content;
}

/*form elements*/

.input-row {
  display: flex;
  grid-column-gap: 5px;
}

.input-row label {
  width: 20%;
}

.input-row input,
.input-row .input-group {
  width: auto;
}

/*header elements*/

div#breadcrumb {
  flex-grow: 1;
  width: auto;
}

.logo--text {
  margin: 0;
}

ol.breadcrumb {
  margin: 0;
}

.navigation--list {
  gap: 7px;
}

div#main-header > div {
  display: flex;
  align-items: center;
}

li.breadcrumb-item a {
  color: #333;
  text-decoration: none;
}

div#user-menu {
  width: max-content;
  display: flex;
  grid-column-gap: 1em;
  align-items: center;
}

.user-avatar {
  height: 70px;
  border-radius: 50%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
}

span.avatar-initials--initial {
  color: #fff;
}

.user-avatar.avatar-initials {
}

.user-avatar--img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background: #eee;
}

.user-avatar--status {
  display: flex;
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.user-avatar img {
  width: 100%;
}

span.user-avatar--status {
  border: 2px solid #fff;
}

span.user-avatar--status.online {
  background: #2cae21;
}

span.user-avatar--atatus.offline {
  background: #eee;
}

/*navigation*/

div#main-navigation {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5em;
}

.flex-column.nav {
  background: #fff;
  height: max-content;
}

.flex-column.nav > a {
  color: #333;
  font-weight: 600;
  padding: 7px 0;
}

.down_nav_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

/*effects&colors*/

.attention-label {
  color: var(--attention-yellow);
  font-weight: 600;
}

.warning-label {
  color: var(--warning-red);
  font-weight: 600;
}

.bg-warning {
  background-color: var(--warning-red);
}

a:hover {
  color: var(--accent-color);
  transition: 0.4s;
}

.input-icon {
  position: relative;
}

.input-icon span.glyphicon {
  position: absolute;
  left: 0;
}

.mh-multiSelect_input {
  max-height: 150px !important;
  overflow: auto !important;
}

.input-icon i {
  position: absolute;
  right: 0.7em;
  top: 0;
  color: #7e7e7e;
  margin-top: auto;
  bottom: 0;
  margin-bottom: auto;
}

.gg-search {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: 0px;
  margin-top: 0px;
}

.gg-search::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 7px;
  left: 9px;
}

/*main elements*/

.btn-primary {
  font-size: 90%;
  background-color: var(--accent-color);
  color: #ffffffdb;
  border: none !important;
  width: max-content;
  border-radius: 20px;
  padding: 5px 15px;
}

.btn-primary:disabled {
  background-color: var(--not-active-grey);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #037250;
}

ul.pagination {
  grid-column-gap: 0.5em;
  margin: 0;
}

ul.pagination .page-item a {
  border-radius: 5px;
  background-color: #f6f6f6;
  border: none;
  padding: 3px 10px;
  color: #333;
}

ul.pagination .page-item.active a {
  background-color: var(--accent-color);
  color: #fff;
}

.progress {
  height: 8px;
}

button.dropdown-toggle {
  border-radius: 5px;
  background: transparent;
  border: 1px solid #eee;
  padding: 5px 15px;
  color: #333;
  align-items: center;
  grid-column-gap: 1em;
  display: flex;
}

.dropdown-toggle::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0 !important;
  content: "";
  border-right: 0.1em solid #686868;
  display: inline-block;
  height: 0.45em;
  border-radius: 1px;
  position: relative;
  transform: rotate(135deg);
  width: 0.45em;
  color: #686868;
}

span.row-menu__button {
  cursor: pointer;
  opacity: 0.5;
}

span.row-menu__button:hover {
  opacity: 1;
}

.fill-questionairy.btn.btn-secondary.btn-sm {
  background: #019467;
  line-height: 1.4em;
  color: white;
}
.fill-questionairy.btn.btn-secondary.btn-sm svg {
  width: 20px;
  height: auto;
  fill: #fff;
}
.fill-questionairy:hover {
  color: white;
  line-height: 1.4em;
}
.btn-label {
  background: var(--neutral-blue);
  border-radius: 5px;
  padding: 3px 10px;
  color: #0064c3;
  font-size: 90%;
}

.btn-label:hover {
  background: #88c0f5;
  border: 1px solid #73b0ea;
  color: #1146aa;
}

input[type="text"]:not(.mnh--text) {
  border-radius: 5px;
  border: 1px solid #eee;
}

.block-title {
  /*заголовки страниц*/
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding-bottom: 1em;
  color: #333;
  padding-left: 0;
}

span#sup {
  /*Счетчик элементов рядом с заголовком*/
  font-weight: 500;
  background: #f6f6f6;
  border-radius: 5px;
  font-size: 13px;
  height: max-content;
  padding: 3px 7px;
  color: #828282;
}

/*navbar tabs*/

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #333;
  font-weight: 700;
  color: #333;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.nav-tabs .nav-link {
  padding: 2px 5px;
  color: var(--not-active-grey);
  font-size: 90%;
}

.nav-tabs .nav-link:not(.active):hover {
  font-weight: 600;
  border: none;
}

/*range кастомный. Но bootstrap тоже ок*/

.range-statistic {
  background: #eee;
  width: 100%;
  max-width: 100px;
  height: 5px;
  border-radius: 10px;
}

.range-statistic--status {
  height: 100%;
  border-radius: 10px;
}

.range-statistic--status.warning {
  background: var(--warning-red);
}

.range-statistic--status.attention {
  background: var(--attention-yellow);
}

.range-statistic--status.success {
  background: var(--success-green);
}

/*dashboards*/

.dashboard-statistic {
  background: #fff;
  border-radius: 10px;
  padding: 1em;
  width: max-content;
}

.dashboard-statistic--icon {
  background: var(--accent-color);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-statistic--stat {
  margin: 0;
}

.dashboard-statistic--icon img {
  width: 100%;
}

.dashboard-statistic--title {
  font-weight: 400;
  font-size: 16px;
  color: #828282;
}

.dashboard-statistic .sub_data {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.dashboard-statistic .up_data {
  font-weight: 700;
  font-size: 12px;
  color: #33b343;
  margin: 0;
  padding: 0;
}

/*research page*/

.disigner {
  grid-row-gap: 1em;
}

.accordion--title,
.accordion--content {
  background-color: transparent !important;
}

form.disigner .accordion {
  background: #fff;
  border-radius: var(--border-round);
}

/*table*/

.table > thead {
  vertical-align: middle;
  padding: 10px 15px;
  background: #edf4fb;
  border-bottom: 2px solid #c7d4e1;
}

.table > thead th {
  border: none;
  font-weight: 400;
  font-size: 90%;
}

.tablecaption {
  font-weight: 400;
  font-size: 13px;
  color: var(--not-active-grey);
  margin: 0;
}

tbody > tr {
  border-bottom: 1px solid #f6f6f6;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0;
}

.wrapper-table {
  width: 100%;
  padding: 0;
}

.checkbox svg {
  width: 15px;
  height: 15px;
}

.table-row--header {
  background-color: #edf4fb;
  padding: 0 !important;
}

.table-row--bg {
  background: #fff;
}

.table-row__item a {
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.table-row__item a:hover {
  color: var(--accent-color);
}

.table > :not(:first-child) {
  border-top: none !important;
}

.table-row,
.table > :not(:first-child) {
  border-bottom: 1px solid #eee;
}

.table-row--bg:nth-child(2n) {
  background: #fcfcfc;
}

.date-picker__header,
.selector__header,
.input-field-search,
.input-field-search input,
.select-count__output,
input[type="text"]:not(.mnh--text),
input[type="text"]:not(.mh-multiSelect_searchInput),
input[type="password"],
textarea:not(.mnh--teatarea) {
  /* background-color: #F8FAFC;
    border: none!important;*/
}

button.all-types__button svg {
  width: 15px;
}

.all-types__button--active {
  font-weight: 700;
}

li.pagination__page a {
  font-size: 90%;
}

.pagination-wrapper {
  padding: 1em 0;
}

li.pagination__page {
  width: max-content;
  min-width: 30px;
  height: max-content;
  line-height: 1em;
  margin: 0;
}

/*
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #fff;
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-accent-bg: var(--light-grey);
} */

a.cell-title {
  font-weight: 700;
  font-size: 14px;
  color: #333;
  text-decoration: none;
}

span.low-attention {
  color: var(--not-active-grey);
  font-size: 90%;
}

/*Вывод анкет старт*/

.chapter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  background: #f7f7f7 !important;
  width: 100%;
  padding: 1.2em !important;
}

.answer input[type="radio"],
.answer input[type="checkbox"] {
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
}

.answer-container {
  display: grid;
}

.answer,
.answer label {
  cursor: pointer;
}

.answer input:checked + label {
  font-weight: 600;
}

.parseScale .btn-group > button {
  width: max-content;
  flex-grow: 0;
}

.parseScale .btn-group {
  grid-gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.parseScale .btn-group > button {
  border-radius: 20px !important;
  margin: 0 !important;
}

.parseScale .btn-group > button:hover {
  background: #eee;
}

.counterTab {
  font-weight: 600;
  padding-bottom: 1em;
  display: flex;
  grid-column-gap: 7px;
}

.mh-dates-range-active {
  opacity: 0.3;
  cursor: no-drop;
}

.answer-wrapper {
  background: #fff;
  padding: 1.2em;
  border-bottom: 1px solid #eee;
}

.answer-wrapper .alert {
  font-size: 90%;
  margin-top: 0;
  max-width: max-content;
  width: 100%;
  padding: 0.8em;
}

.answer-wrapper input {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.answer-wrapper .btn.btn-secondary {
  background: #fcfcfc;
  color: #333;
  border-color: #eee;
}

.answer {
  grid-column-gap: 0.5em;
  display: flex;
  padding: 0.5em;
  line-height: 1.2em;
  border-radius: 20px;
}

.answer:hover {
  background: #eee;
}

span.form-counter {
  color: var(--accent-color);
}

.answer-wrapper .container-fluid,
.answer-wrapper .col,
.answer label,
.answer-wrapper .btn-group,
.answer-wrapper .row,
.answer-wrapper input {
  /*  padding: 0; */
}

/*Конструктор исследований start*/

.builder--researches {
  max-height: max-content !important;
  overflow-y: unset !important;
}

.builder--researches--options {
  flex: 0 0 21%;
  top: 60px;
  padding: 0 1em;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.tabs--content {
  max-height: max-content !important;
}

.tp--title--wrap h4 span::after {
  position: relative !important;
}

.mh-constructorWrapper__chapter {
  background: #f5f5f5 !important;
}

.mh-constructorWrapper__sub {
  margin: 0 auto !important;
  width: calc(100% - 20px) !important;
}

table.mh-constructorTable {
  table-layout: fixed;
}

.template {
  width: max-content !important;
  min-width: auto !important;
  top: 60px;
}

.mh-constructorWrapper > .tp {
  margin: -15px 0px !important;
}

.ModalQuestionTerms_wrapper__lVcZp {
  overflow: unset !important;
}

.tp--title--wrap {
  align-items: flex-start !important;
}

.mh-constructorWrapper:hover {
  background: var(--light-blue) !important;
}

.mh-constructorWrapper__selected {
  border-left: 4px solid #00a1a0 !important;
}

.mh-constructor_scale .mh-constructor_scaleItem,
.mh-constructorTable {
  background: #fff !important;
}

.mh-constructor_number,
.mh-constructor_checkBoxes,
.mh-constructor_list,
.mh-constructor_scale {
  background-color: transparent !important;
}

.mh-listEditor_item:not(:last-child) {
  margin-bottom: 10px !important;
}

.mh-listEditor_itemText {
  background-color: #f8fafc !important;
  padding: 5px !important;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.mh-listEditor_itemButtons {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0 !important;
  background: #f8fafc !important;
}

.mh-listEditor_item:hover .mh-listEditor_itemButtons {
  display: flex !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.mh-select_list {
  width: 100%;
  max-height: 250px !important;
  overflow-y: auto !important;
  margin: 0;
}

input.mh-multiSelect_searchInput {
  background: #fff !important;
  width: 100%;
}

/*Конструктор исследований finish*/

/*фикс для заполнения анкеты*/

input.mh-dates_value {
  border: none !important;
  cursor: pointer;
  caret-color: transparent;
}

@media (max-width: 650px) {
  .page--routs {
    padding: 0;
  }
  div#page-content {
    padding: 1em;
  }
  .chapter,
  .answer-wrapper {
    padding: 10px !important;
  }
  .logo--icon {
    width: 23px;
    height: 30px;
  }
  .table {
    overflow-x: auto;
  }
  .aside {
    height: auto;
    padding: 0 15px;
  }
  .header--menu .user--name {
    display: none;
  }
  button.menu-toggle {
    border: none;
    padding: 0;
    width: max-content;
  }
  span.navbar-toggler-icon {
    width: 25px;
    display: flex;
    height: 25px;
  }
  .aside--title {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .actions--exit {
    display: none;
  }
  ul.nav.nav-tabs {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
  .table-row__item:last-child {
    justify-content: flex-start;
  }
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    width: 100%;
    margin: 0;
  }
}
