.mh- {
  &multiSelect {
    width: 100%;
    position: relative;

    &_input {
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      display: flex;
      padding: 6px 12px;
      min-height: 35px;
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      &Items {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: 12px;
      }

      &Item {
        height: 23px;
        border-radius: 5px;
        background: #edf4fb;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        margin-right: 5px;
        margin-bottom: 5px;

        &Text {
          font-size: 11px;
          color: #444444;
          margin-right: 10px;
          line-height: 10px;
          span {
            display: none;
          }
        }

        &Btn {
          border: none;
          background: none;
          height: auto;
        }
      }

      img {
        // width: 18px;
        // height: 23px;
        object-fit: contain;
        object-position: center;
      }
    }

    &_listBlock {
      width: 100%;
      display: none;
      flex-direction: column;
      position: absolute;
      z-index: 3;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &__shown {
        display: flex;
      }
    }

    &_searchBlock {
      padding: 10px;
      background: #edf4fb;
      display: flex;
    }

    &_searchField {
      width: 100%;
      border-radius: 8px;
      background: #ffffff;
      display: flex;
      align-items: center;
      padding: 0px 18px;
      height: 36px;
    }

    &_searchInput {
      border: none !important;
      font-size: 12px;
      line-height: 12px;
    }

    &_cancelSearch {
      border: none;
      background: none;
      margin-left: 10px;
    }

    &_items {
      list-style: none;
      padding: 17px 15px;
      background: #ffffff;
      max-height: 300px;
      overflow-y: auto;
    }

    &_item {
      &:not(:last-child) {
        margin-bottom: 17px;
      }
    }

    &_footer {
      padding: 15px 16px;
      background: #edf4fb;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_button {
      padding: 0px 15px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background: #019467;
      color: #ffffff;
      font-size: 14px;
      line-height: 14px;
      border: none;
    }

    &_count {
      color: #888888;
      font-size: 12px;
      line-height: 12px;
    }
  }
}
