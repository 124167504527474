@import "../../styles/colors.scss";
.popup {
    position: absolute;
    right: 0;
    padding-top: 30px;
    border: 1px solid #eee;
    width: 243px;
    background: #ffffff;
    border-radius: 10px;
    padding: 15px;
    .popup--list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        &--item,
        &--item:hover {
            width: 100%;
            font-size: 14px;
            line-height: 19px;
            text-decoration: none;
            color: $text;
        }
        .popup--list--item--exit {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 15px;
            gap: 14px;
            width: 72px;
            height: 29px;
            background: $accent-color;
            border-radius: 5px;
            color: #ffffff;
        }
    }
}

.show {
    display: block;
}

.hide {
    display: none;
}