.tp {
  position: relative;
  min-width: 100%;
  padding: 10px;
  transition: padding 0.3s ease-in-out, margin 0.3s ease-in-out;

  &--number {
    position: absolute;
    left: 10px;
    top: 30px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #00a1a0;
  }

  &--title {
    display: flex;
    position: relative;
    gap: 10px;
    &--wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 20px;

      h4 {
        position: relative;
        padding-left: 40px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0px;
        max-width: 480px;
        span::after {
          position: absolute;
          width: 6px;
          height: 6px;
          content: "*";
          color: red;
          top: 0px;
        }
      }
    }
    .tp--action {
      display: none;
      position: absolute;
      top: 0px;
      right: 10px;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      flex-direction: row;
      flex-wrap: nowrap;
      &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        background: none;
      }
    }
  }
  .conditional {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    &--button {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border: none;
      border-radius: 5px;
      position: relative;
      &-green {
        background: #019467;
      }
      &-yellow {
        background: #ffba52;
      }
      &-blue {
        background: #97ccff;
      }
      &-show {
        display: flex;
      }
      &-hide {
        display: none;
      }
    }
  }

  &--note {
    display: flex;
    align-items: center;
    height: 40px;
    background: #feedee;
    border-radius: 5px;
    padding: 0px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #9e4d52;
    margin: 0px 0px 10px 40px;
  }

  &--content {
    padding-left: 40px;
  }
}

.tp--title:hover > .tp--action {
  display: flex;
}

.tp--hide {
  display: none;
}

.question-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 20;
  &--condition {
    padding: 5px 0px;
    font-size: 14px;
    color: black;
  }
  &--condition:not(:last-child) {
    border-bottom: 1px solid #bec8d0;
  }
}
