.mh- {
    &checkbox {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 0px 15px 10px 0px;
        user-select: none;
        &_item {
            display: none;
        }
        &_field {
            width: 15px;
            height: 15px;
            min-width: 15px;
            border: 1px solid #C2C2C2;
            border-radius: 4px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &_item:checked+&_field {
            background: #00A1A0;
            border: #00A1A0;
        }
    }
}