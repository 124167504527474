.mh-{
    &listEditor {


        &_list {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-top: 6px;
            margin-bottom: 15px;
        }
        
        &_type {
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            &Scale {
                width: 15px;
                height: 15px;
                border-radius: 5px;
                border: 1px solid #E0E0E0;
                font-size: 10px;
                line-height: 10px;
                color: #616161;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px 0px 3px 0px;

                img {
                    transform: rotate(-90deg) scale(0.5);
                }
            }

            .mh-checkbox, .mh-radio {
                margin: 0px 0px 4px 0px;
            }
        }

        &_item {
            position: relative;
            display: flex;
            align-items: center;
            z-index: 2;

            &__moving:not(&__dragged) {
                transition: transform 0.3s ease-in-out;
            }

            &Text {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 0px;
                width: 100%;
                background: none;
                border: none;
            }

            &Buttons {
                width: 60px;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &_actionButton {
            background: none;
            border: none;
        }

        &_addButton {
            height: 30px;
            padding: 0px 15px;
            border: 1px solid #D4D4D4;
            display: flex;
            align-items: center;
            border-radius: 5px;
            background: none;

            img {
                margin-right: 15px;
            }
        }
    }
}