@import "../../styles/colors.scss";

* {
   font-family: "Nunito Sans", sans-serif;
   color: $text;
   margin: 0px;
   padding: 0px;
   box-sizing: border-box;
   outline: none;
}

.chat- {
   &Wrapper {
      position: relative;
      width: 100%;
      min-height: calc(100vh - 80px);
      max-height: calc(100vh - 80px);
      height: 100%;
      display: flex;
      flex-direction: row;
   }

   &Contacts {
      width: 290px;
      min-width: 290px;
      min-height: 100%;
      background: $white;
      display: flex;
      flex-direction: column;

      &_search {
         display: flex;
         align-items: center;
         min-height: 56px;
         padding: 0px 16px;
      }
   }
   &Area {
      width: 100%;
      min-height: 100%;
      max-height: 100%;
      background: $mid-grey;
   }

   &MessageSearch {
      display: none;
      width: 370px;
      min-width: 370px;
      background: $white;
      flex-direction: column;

      &__active {
         display: flex;
      }
   }
}
