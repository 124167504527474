@import "../../../styles/colors.scss";

@import "../../../styles/colors.scss";

.limit-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.user--researches {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  &--table-container {
    max-height: 300px;
    margin-top: 15px;
    overflow-y: auto;
  }
  &--table {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
    }
    td,
    th {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    td {
      font-size: 0.95rem;
    }
    td span {
      font-size: 0.9rem;
      color: #858585;
    }
    tbody tr:nth-child(even) {
      background-color: var(--light-blue);
    }
  }
  &--card {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &--form {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;

    gap: 15px;

    min-width: 100%;

    list-style: none;

    margin: 0px;
    padding: 0px;
    &--item {
      min-width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      flex-direction: row;

      gap: 5px;

      .item--text {
        min-width: 200px;
        font-size: 14px;
        line-height: 20px;
      }

      &-horizontal {
        flex-direction: column;
        width: 100%;
        .item--text {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-link {
            color: #019467;
            cursor: pointer;
          }
        }
      }
    }
  }
  &--alert {
    width: 290px;
    font-size: 14px;
  }
  &--error {
    color: #ff837b;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
  }
}
