@import "../../../../styles/colors.scss";
.header--button,
.button {
    /*  width: 22px;
   height: 22px; */
    .button--icon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.header--button--new {
    position: relative;
}

.header--button--new::after {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 8px;
    width: 8px;
    height: 8px;
    content: "";
    background-color: $success-green;
}