@import "../../../styles/colors.scss";

.mnh--text {
   width: 100%;
   height: 35px;
   display: flex;
   align-items: center;
   padding: 0px 12px;
   background: $white;
   border: 1px solid #E0E0E0;
   border-radius: 4px;

   border-radius: 4px;
}
