@import "../../../styles/variable";
.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0!important;
}

.pagination {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    &__button {
        width: 44px;
        height: 32px;
        background-color: #F6F6F6;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &--prev:after {
            position: absolute;
            top: 6px;
            content: url("../../../assets/svg/prev.svg");
            width: 6px;
            height: 10px;
            display: inline-block;
        }
        &--next:after {
            position: absolute;
            top: 6px;
            content: url("../../../assets/svg/next.svg");
            width: 6px;
            height: 10px;
            display: inline-block;
        }
    }
    &__page {
        width: 44px;
        height: 32px;
        background-color: #F6F6F6;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 6px;
        &:first-of-type {
            margin-left: 12px;
        }
        &:last-of-type {
            margin-right: 12px
        }
        &--active {
            background-color: $AccentColor!important;
            color: #ffffff;
        }
    }
    &__break {
        width: 44px;
        height: 32px;
        background-color: #F6F6F6;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 6px;
    }
}

.select-page-count {
    display: flex;
    align-items: center;
    position: relative;
    &__text {
        font-family: $fontInter;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $Text;
        margin: 0 8px;
    }
}

.action-buttons {
    display: flex;
    align-items: center;
    & button {
        margin-left: 8px;
    }
}
@media (max-width: 575.98px) {
.pagination-wrapper {
    display: flex;
    flex-wrap: wrap;
}}