.mn-tag {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 26px;
    padding: 3px 7px;
    border-radius: 5px;
    background: #97CCFF;
    width: fit-content;
}

.mn-tag span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0064C4;
}

.mn-tag svg {
    cursor: pointer;
}
