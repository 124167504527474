.mh- {
  &modalAddBlock {
    &_item {
      background: #d4e8fd;
      border-radius: 5px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      width: 170px;
      cursor: pointer;

      &Image {
        margin-right: 10px;
        max-width: 21px;
        max-height: 21px;
      }

      &Title {
        width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
