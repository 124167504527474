.mnh- {
  &tableEditor {
    width: 100vw;
    max-width: 1000px;
    &_header {
      margin: 20px 0px;
      display: flex;
      justify-content: flex-end;
    }
    &_button {
      height: 30px;
      border-radius: 5px;
      align-items: center;
      padding: 0px 15px;
      border: 1px solid #d4d4d4;
      background: #ffffff;
      img {
        margin-right: 10px;
      }
    }
    &_tableArea {
      min-height: 400px;
      max-height: 400px;
      overflow: auto;
    }
    &_table {
      min-width: 100%;
    }
    &_header {
      &Type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        padding-bottom: 10px;
      }
      &Actions {
        display: flex;
        align-items: center;
      }
      &Text {
        background: #edf4fb;
        display: flex;
        align-items: center;
      }
      &Input {
        height: 40px;
        background: none;
        border: none;
        width: 100%;
        padding: 0px 10px;
        font-size: 14px;
      }
    }
    &_cell {
      padding: 10px;
      min-height: 60px;
      &Title {
        border: none;
        width: 100%;
      }
      &Input {
        background: #f8fafc;
        border: none;
        width: 100%;
        border-radius: 5px;
        padding: 5px 15px;
      }
      &Check {
        display: flex;
        align-items: center;
        label {
          margin-bottom: 0px;
        }
      }
    }
    &_afterTable {
      display: flex;
      padding: 20px 0px;
    }
    &_actionButton {
      border: none;
      background: none;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    &_footer {
      display: flex;
      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    &_saveBtn {
      padding: 0px 15px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      border: none;
      background: #019467;
      color: #ffffff;
    }
    &_removeBtn {
      padding: 0px 15px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      border: none;
      background: #ff837b;
      color: #ffffff;
      img {
        margin-right: 10px;
      }
    }
  }
}

.mnh-tableEditor {
  .form-check-input:checked {
    background-color: #019467;
    border-color: #019467;
  }
}
