.calendarDatepicker {
  cursor: pointer;
}

.calendarDatepicker .react-datepicker__day--selected {
  background-color: var(--accent-color);
}
.calendarInput {
  max-width: 100%;
  height: 32px;
  padding: 0 10px;
  /* margin-bottom: 10px; */
  cursor: pointer;
}
.calendarInputWithoutOverlay {
  cursor: text;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  transform: translate3d(120px, 0px, 0px);
}
/* .react-datepicker{

    &-wrapper {
        width: 100%;
    }

    &__input-container {
        width: 100%;
        border-radius: 4px;
        padding: 0px 12px;
        border: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        height: 35px;
        justify-content: space-between;
        cursor: pointer;
        & > input {
            width: 100%;
            border: none !important;
            color: #757575;
            font-size: 14px;
            line-height: 14px;
            padding-right: 10px;
            background: none;
        }
    }

    &-popper {
        width: 300px;
        border-radius: 5px;
        padding: 10px;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.25);
        position: absolute;
        z-index: 3;
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__day-names {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &__day-name {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        font-size: 12px;
        line-height: 12px;
        color: #7B827E;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    &__month {
        // display: flex;
        // width: 100%;
        // flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
    }
    &__week {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    &__day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        font-size: 14px;
        line-height: 14px;
        border: 1px solid #ffffff;
        cursor: pointer;

        &--outside-month {
            color: #6C7470;
        }

        &--selected {
            background: #019467;
            font-weight: 700;
            color: #ffffff;
        }

        &--excluded {
            color: #6C7470;
            opacity: 0.4;
        }
    }
}

.mh- {
    &dates {
        width: 100%;
        &_input {
            width: max-content;
            border-radius: 4px;
            padding: 0px 12px;
            border: 1px solid #E0E0E0;
            display: flex;
            align-items: center;
            height: 35px;
            justify-content: space-between;
            &__filed {
                border: none;
                background: #F8FAFC;
            }
        }
        &_value {
            color: #757575;
            font-size: 14px;
            line-height: 14px;
            padding-right: 10px;
        }
        &_select {
            width: 100%;
            margin: 0px 5px;
        }
        &_picker {
            display: none;
            border-radius: 5px;
            padding: 10px;
            background: #ffffff;
            box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.25);
            position: absolute;
            z-index: 3;
            &__shown {
                display: flex;
            }
        }
        &_calendar {
            width: 290px;
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
        &_monthControler {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        &_monthButton {
            border: none;
            background: none;
        }
        &_monthName {
            font-weight: 700;
        }
        &_week {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        &_dayOfWeek {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            font-size: 12px;
            line-height: 12px;
            color: #7B827E;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &_calendar {
            &View {
                position: relative;
                width: 100%;
            }
            &Week {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
            &Day {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 32px;
                font-size: 14px;
                line-height: 14px;
                border: 1px solid #ffffff;
                cursor: pointer;
                &__secondary {
                    color: #6C7470;
                }
                &__between {
                    background: rgba(0, 161, 160, 0.14);
                    color: #6C7470;
                }
                &__selected {
                    background: #019467;
                    font-weight: 700;
                    color: #ffffff;
                }
            }
        }
    }
} */
