.upload {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.label {
  display: flex;
  align-items: center;
  background: #F6F6F6;
  border-radius: 5px;
  padding: 8px 9px;
  cursor: pointer;
  gap: 8px;

  img {
    width: 16px;
    height: 16px;
  }

  input {
    display: none;
  }
}

.files {
  display: flex;
  align-items: center;
  gap: 6px;

  img {
    cursor: pointer;
  }
}

.button {
  &:disabled {
     background-color: #6f6f6f;
  }
}

.alert {
  width: 290px;
  font-size: 14px;
}

.error {
  color: #FF837B;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
}
