@import "../../../styles/colors.scss";

.chat-messageSearch {
   &Header {
      background: $dark-grey;
      display: flex;
      flex-direction: row;
      height: 56px;
      align-items: center;
      padding: 0px 10px;

      &_title {
         font-size: 14px;
         line-height: 14px;
         font-weight: 700;
         width: 100%;
      }

      &_closeBtn {
         display: flex;
         width: 35px;
         min-width: 35px;
         height: 35px;
         align-items: center;
         justify-content: center;
         cursor: pointer;
         border: none;
         background: none;
      }
   }

   &Input {
      padding: 15px;
   }

   &Results {
      display: flex;
      flex-direction: column;
   }

   &Result {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-bottom: 1px solid #c6c6c6;
      cursor: pointer;

      &:hover {
         background: #fcfcfc;
      }

      &_date {
         font-size: 14px;
         line-height: 20px;
         margin-bottom: 10px;
         color: $light-text;
      }

      &_text {
         font-size: 14px;
         line-height: 20px;
      }
   }
}
