@import url('https://fonts.googleapis.com/css2?family=Inter&family=Nunito+Sans:wght@300;400;600&display=swap');
@import "styles/variable";
@import "styles/fields";
.title-h1 {
    font-family: $font;
    font-weight: 700;
    font-size: 23px;
    line-height: 31px;
    color: $Text;
    margin: 0;
}