@import "../../tables/styles/_variable.scss";
.wrapper-table {
  //width: 1177px;
  //width: 100%;
  margin-right: auto;
  /*  padding: $padding-25; */
}

.table {
  width: 100%;
}

.table-header {
  padding-bottom: 16px;
}

.table-row.table-row--bg:hover {
  background: #f2f2f2;
}

.table-row__item .form-status {
  padding: 5px 8px;
  display: block;
  width: max-content;
  font-size: 90%;
  grid-column-gap: 10px;
  align-items: center;
  display: flex;
  border-radius: 5px;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    display: block;
    border-radius: 50%;
  }
}

.table-row__item .form-status.draft {
  background: #ffe5a1;
  &::before {
    content: "";
    background: #f2ae00;
  }
}
.cell-column {
  display: flex;
  flex-direction: column;
}
span.cell—light-grey {
  color: #919191;
}
.visit-info {
  display: flex;
  gap: 2px;
}
.visit-info span {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  font-size: 11px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visit-info span.success {
  background-color: #4caf50;
}
.visit-info span.draft {
  background-color: #ffc107;
}
.visit-info span.moderate {
  background-color: #2196f3;
}
.visit-info span.not_filled {
  background-color: #888888;
}
.table-row__item .form-status.accepted {
  background: #c3ffdd;
  &::before {
    content: "";
    background: #019467;
  }
}

.table-row__item .form-status.moderation {
  background: #ffe5a1;
  &::before {
    content: "";
    background: #f2ae00;
  }
}

.table-row {
  width: 100%;
  // display: flex;
  align-items: center;
  padding: 5px 0;
  /*       */
  box-shadow: 0px 1px 0px rgba(102, 102, 102, 0.25);
  button {
    display: none;
  }
  &:hover button {
    display: inline-flex;
  }
  &--header {
    background-color: #f6f6f6;
  }
  &--action-text {
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: var(--accent-color);
    }
  }
  & p {
    margin: 0;
  }
  &__item {
    padding: 8px $padding-5;
    // &:last-child {
    //   flex-grow: 1;
    //   display: flex;
    //   justify-content: flex-end;
    // }
  }
  &__wrap-text {
    display: flex;
    flex-direction: column;
  }
  &__text {
    font-family: $font;
    font-weight: 400;
    font-size: 14px;
    color: $Text;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__subtext {
    font-family: $font;
    font-weight: 400;
    font-size: 13px;
    color: $NotActiveGrey;
  }
}

.table-head {
  // display: flex;
  align-items: center;
  font-family: $font;
  font-weight: 400;
  font-size: 13px;
  color: $Text;
  &--icon {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }
}

.status {
  position: relative;
  &__output {
    width: 72px;
    height: 25px;
    border-radius: 5px;
    font-family: $font;
    font-weight: 400;
    font-size: 12px;
    color: $Text;
    padding: $padding-5;
  }
  &__icon {
    margin-left: 15px;
    cursor: pointer;
  }
  &-circle {
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
  }
  &__list {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: $padding-15;
    background-color: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
  &__item {
    font-family: $font;
    font-weight: 300;
    font-size: 14px;
    color: $NotActiveGrey;
    padding: 7px 0;
    &:hover {
      cursor: pointer;
    }
  }
}

.row-menu {
  display: flex;
  justify-content: flex-end;
  &__button {
    padding: 0 9px;
  }
}

.all-types {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: $padding-15;
  &__button {
    border: none;
    background-color: transparent;
    font-family: $font;
    font-weight: 400;
    font-size: 14px;
    color: $Text;
    padding: 4px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: 0.5s;
    margin-right: 18px;
    &--active {
      border-bottom: 2px solid $Text;
      transition: 0.5s;
      font-weight: 700;
    }
    svg {
      margin-right: 6px;
    }
  }
  &__count {
    width: 31px;
    height: 20px;
    background-color: #f7f7f7;
    border-radius: 5px;
    font-family: $font;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $NotActiveGrey;
    padding: 0 8px;
    display: inline-block;
    margin-left: 8px;
  }
}

.filters {
  display: flex;
  padding-bottom: 20px;
  &__item {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}

.static-buttons {
  display: flex;
  padding: 0 15px;
  &__button {
    font-family: $font;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0064c4;
    border: none;
    outline: none;
    padding: 3px 7px;
    border-radius: 5px;
    background-color: $NeutralBlue;
    margin-right: 10px;
    cursor: pointer;
  }
}

.table-footer {
  display: flex;
}

.active-filters {
  padding-bottom: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.clear-active-filters {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #0064c4;
  cursor: pointer;
}

.table-head {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__add {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__menu {
    flex-direction: column;
    gap: 10px;
    inset: unset !important;
    right: 5px !important;
    top: calc(100% + 5px) !important;
    transform: unset !important;
    a {
      text-decoration: none;
      font-size: 14px;
      color: #333333;
    }
    &.show {
      display: flex;
      padding: 5px 10px;
    }
  }
}

.filter-clear {
  font-size: 14px;
  background-color: white;
  border-radius: 5px;
  color: #0064c4;
  img {
    margin-left: 8px;
  }
}

@media (max-width: 576px) {
  .all-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  button.table-head__add {
    margin-bottom: 10px;
  }
  .table-head__wrapper {
    flex-wrap: wrap;
  }
  .table-row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    &__item:not(:first-child) {
      border-top: 1px solid #eee;
    }

    &__wrap-text {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
    }
    &__mobile-column-title {
      font-size: 0.9rem;
      color: #797979;
    }
  }
}
