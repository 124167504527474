@import "../../styles/colors.scss";

.header--breadcrumbs {
   display: flex;
   flex-direction: row;
   align-items: center;

   &--link {
      display: inline-block;
      color: $non-grey;
      text-decoration: none;

      &:not(:last-child) {
         margin-right: 10px;
      }
   }

   &--arrow {
      margin-right: 10px;
   }
}
