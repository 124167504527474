.itme--textarea {
   min-width: 100%;
   min-height: 60px;
   background: #F8FAFC;
   padding: 6px 12px;
   border-radius: 4px;
   border: none;
   color: #757575;
   font-size: 14px;
   line-height: 20px;
}
