@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap");

* {
   color: #333333;
}

.light {
   font-style: normal;
   font-weight: 300;
}

.regular {
   font-style: normal;
   font-weight: 400;
}

.semi__bold {
   font-style: normal;
   font-weight: 600;
}

.bold {
   font-style: normal;
   font-weight: 700;
}
.wrapper-status{
  flex-wrap: nowrap;
  display: flex;
}
