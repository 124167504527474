.wrapper {
  position: relative;
}

.content {
  height: fit-content;
  position: sticky;
  top: 60px;
  padding: 1.5em;
  border-radius: var(--border-round);
  background: #fff;
  user-select: unset;
  max-height: 90vh;
  overflow-y: auto;
  * {
    user-select: text;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  display: inline-block;
  margin-bottom: 20px;
}

.params {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #888888;
  }
}

.files {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  max-height: 220px;
  .file {
    background: #fcfcfc !important;
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    img {
      width: 25px;
      height: 25px;
    }
    &Content {
      display: flex;
      flex-direction: column;
      flex-grow: 1 !important;
      .name {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .size {
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #8f8f8f;
      }
    }
  }
}

.download {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #019467;
  text-decoration: none;
}

.contentRow {
  display: flex;
  justify-content: space-between;
}

.danger {
  border-radius: 19px;
  background: #ff837b;
  border-color: #ff837b;
  display: flex;
  gap: 10px;
  align-items: center;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  align-items: center;
  .danger {
    border-radius: 19px;
    background: #ff837b;
    border-color: #ff837b;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  img {
    width: 15px;
    fill: #fff;
    height: 15px;
  }
}

.mh-formValidationMessage {
  display: flex;
  max-width: 100%;
  width: max-content;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

@media (max-width: 576px) {
  .buttons {
    position: fixed;
    bottom: 0px;
    background: #fff;
    left: 0;
    width: 100%;
    padding: 1em;
    box-shadow: 0 0 10px 0 #c4c4c4;
    justify-content: center;
  }
  div#form-viewer {
    flex-direction: column-reverse;
  }
  .wrapper {
    padding: 0;
  }
}

.calculator {
  & .label {
    font-size: 12px;
    font-weight: 700;
  }
  .button {
    background-color: #97ccff;
    color: #32414f;
    font-size: 13px;
  }
  .button:hover {
    background-color: #97ccff;
  }
  .button:focus {
    background-color: #97ccff;
  }
}
