.option--menu {
  width: 100%;

  &--header {
    width: 100%;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #d4d4d4;
  }

  &--title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #333333;
  }

  &--conditional {
    display: flex;

    &--button {
      width: 24px;
      height: 24px;
      border-radius: 5px;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      transition: 0.4s ease-out background-color, 0.4s ease-out border-color;

      &.notify-yellow {
        background-color: #ffba52;
      }

      &.mn-green {
        background-color: #019467;
        border-color: #019467;
      }
      &.mn-blue {
        background-color: #97ccff;
        border-color: #97ccff;
      }
    }
  }

  &--block {
    margin: 10px 0px 15px 0px;
  }

  &--label {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    margin-bottom: 5px;
  }

  &--input {
    padding: 12px 6px;
    font-size: 14px;
    line-height: 19px;
    width: 100%;
  }

  &--limit {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &Text {
      width: 18px;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
      margin-right: 5px;

      &:not(:first-child) {
        margin-left: 5px;
      }
    }
  }

  &--layout {
    display: flex;
    align-items: center;

    &Button {
      display: flex;
      align-items: center;
      padding: 0px 10px;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      background: #ffffff;
      height: 32px;

      &-active {
        background: #edf4fb;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &--bottom {
    padding: 10px 0px 15px;
  }

  &--tableButton {
    border: 1px solid #d9d9d9;
    height: 50px;
    border-radius: 5px;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    background: #ffffff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;

    img {
      margin-right: 7px;
    }
  }

  &--delete {
    height: 30px;
    padding: 0px 15px;
    background: #ff837b;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    border: none;

    img {
      margin-right: 10px;
    }
  }
}
