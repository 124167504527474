.mnh- {
  &typeSelect {
    position: relative;

    &_button {
      border: none;
      background: none;
    }

    &_arrow {
      transform: rotate(-90deg) scale(0.6);
    }

    &_button:hover + &_list,
    &_list:hover {
      display: block;
    }

    &_list {
      display: none;
      position: absolute;
      padding: 10px 0px;
      border-radius: 5px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &Item {
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:hover {
          background: rgba(0, 161, 160, 0.14);
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
