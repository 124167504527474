@import "../../../styles/colors.scss";

.popver--item {
   list-style: none;
   height: 20px;
}

.popover--item--link {
   text-decoration: none;

   font-size: 14px;
   line-height: 19px;

   color: $text;
   &:hover {
      color: $text;
   }
}
