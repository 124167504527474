.react-form-builder.clearfix > div {
   display: flex;
}
.react-form-builder .react-form-builder-preview {
   width: 70% !important;
}
.react-form-builder-toolbar {
   width: 30% !important;
}
.default-tabs li:nth-child(1) button {
   display: none !important;
}
.edit-form-attached {
   width: 30%;
   display: inline-block;
}
.Sortable {
   width: 70%;
   display: inline-block;
   height: 100%;
   float: right;
}
.react-form-builder-preview {
   flex-direction: row-reverse;
   display: flex;
}
.float-right.fas.fa-times.dismiss-edit {
   display: none;
}
.rdw-editor-toolbar {
   display: none;
}
.react-form-builder-toolbar {
   margin-top: 0px !important;
}
