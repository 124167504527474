@import "../../styles/colors.scss";

.quick--action--button {
   padding-left: 7px;
   padding-right: 7px;
   padding-top: 3px;
   padding-bottom: 3px;

   display: flex;
   align-items: center;
   justify-content: center;

   background-color: $nautral-blue;

   text-decoration: none;
   border-radius: 5px;
}

.quick--action--button--text {
   font-size: 12px;
   line-height: 20px;

   color: $black-to-blue;
}
