.delete-selected-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0px #00000040;
  position: fixed;
  left: 26%;
  bottom: 15px;
  background-color: white;
  z-index: 20;

  &--info {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;

    &--close {
      cursor: pointer;
    }
  }

  &--delete {
    height: 30px;
    padding: 0px 15px;
    background: #ff837b;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    border: none;

    img {
      margin-right: 10px;
    }
  }
}
