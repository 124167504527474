.mh- {
    &toast {
        position: fixed;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        right: 0px;
        border-radius: 5px;
        top: 30px;
        z-index: 999;
        max-width: 400px;
        max-height: 100%;
        margin: 0 10px 10px 0px;
        &_item {
            display: flex;
            padding: 1em;
            border-radius: 4px;
            background: #eeeeee;
            animation: shown 0.3s ease-in-out 1;
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
            @keyframes shown {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            &__error {
                background: #FEEDEE;
                border-color: #E98D91;
            }
            &__success {
                background: #F1FAF0;
                border-color: #7CB97A;
            }
            &__warning {
                background: #FDF8DE;
                border-color: #FFEC80;
            }
            &__info {
                background: #E7EDF7;
                border-color: #9FC2F5;
            }
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        &_img {
            width: 25px;
        }
        &_contentWrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        &_header {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
        &_title {
            font-size: 100%;
            line-height: 20px;
            font-weight: 600;
            margin: 0px;
            width: 100%;
        }
        &_close {
            border: none;
            background: none;
            &__img {
                width: 20px;
            }
        }
        &_msg {
            font-size: 90%;
        }
        &_content {
            color: #757575;
            font-size: 16px;
            grid-column-gap: 10px;
            line-height: 24px;
            display: flex;
            align-items: center;
        }
    }
}