@import "../../styles/colors.scss";
.accordion {
    position: relative;
    &--check {
        position: absolute;
        left: 0;
        top: 0;
        height: 52px;
        width: 100%;
        opacity: 0;
        &:checked~.accordion--title .accordion--title--text::before {
            transform: rotate(-90deg);
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.221 5.779 9.656 9.22l-3.435 3.443L7.28 13.72l4.5-4.5-4.5-4.5L6.22 5.78Z' fill='%23A4A4A4'/%3E%3C/svg%3E%0A");
        }
        &:checked~.accordion--title~.accordion--content {
            overflow: visible;
            max-height: max-content;
        }
    }
    &--title {
        background: $white;
        color: $text;
        font-size: 19px;
        line-height: 26px;
        position: relative;
        margin: 0px;
        &--text {
            cursor: pointer;
            position: relative;
            display: flex;
            font-weight: 700;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            padding: 25px;
        }
        &--text::before {
            width: 27px;
            height: 27px;
            content: "";
            display: inline-block;
            transform: rotate(90deg);
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.221 5.779 9.656 9.22l-3.435 3.443L7.28 13.72l4.5-4.5-4.5-4.5L6.22 5.78Z' fill='%23A4A4A4'/%3E%3C/svg%3E%0A");
            transition: transform 0.5s;
            margin: 0px;
        }
    }
    &--content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s;
        background-color: white;
        .content--container {
            padding-top: 11px;
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 25px;
        }
    }
}