// Design colors

$accent-color: #019467;
$nautral-blue: #97ccff;
$attantion-yellow: #ffba52;
$black-to-blue: #0c1e5b;
$warning-red: #ff837b;
$success-green: #9bdb5a;
$light-blue: #edf4fb;
$light-grey: #fcfcfc;
$non-grey: #888888;
$text: #333333;
$mainBlue: #8babd8;
$light-blue: #edf4fb;
$text: #011627;
$light-text: #c6c6c6;
$link: #2573b2;
$message-badge: #78e378;
$message-background: #d9fdd3;
$message-info: #68a15e;
$dark-grey: #f2f2f2;
$darker-grey: #969696;
$mid-grey: #eeeeee;
$light-grey: #f5f5f5;
$white: #ffffff;
