.mh-{
    &constructorTable {
        width: 100%;

        thead tr {
            background: #EDF4FB;

            td {
                font-size: 14px;
                line-height: 20px;
                padding: 10px;
            }
        }

        tbody tr {

            td {
                padding: 10px;
                border-bottom: 1px solid #F1F1F1;
            }
        }

        &_cellCheck {
            display: flex;
            align-self: center;

            label {
                margin-bottom: 0px;
            }
        }
    }
}