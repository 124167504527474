@import "../../../views/messages/index.scss";

.chat- {
   &contact {
      height: 72px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      cursor: pointer;
      border-bottom: 1px solid $light-grey;

      &_avatar {
         margin-right: 16px;
      }

      &_info {
      }

      &__messageInfo {
      }

      &:hover {
         background: #fcfcfc;
      }
   }

   &contactInfo {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &_name {
         font-weight: 700;
         font-size: 14px;
         line-height: 20px;
         margin-bottom: 4px;
      }

      &_lastMessage {
         font-size: 14px;
         line-height: 18px;
         color: $light-text;
         margin-bottom: 0rem;

         &__unreaded {
            color: #707991;
         }
      }
   }

   &messageInfo {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-end;
      // justify-content: left;

      &_time {
         font-size: 12px;
         line-height: 16px;
         color: $light-text;
         margin-bottom: 6px;
      }

      &_count {
         width: 18px;
         height: 18px;
         border-radius: 9px;
         background: $message-badge;
         color: $white;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 12px;
         line-height: 12px;
         margin-bottom: 0rem;
      }
   }

   &emptyContacts {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px 10px;

      &_title {
         margin-bottom: 5px;
         font-size: 18px;
         text-align: center;
         font-weight: 600;
      }

      &_text {
         margin: 0px;
         text-align: center;
         line-height: 130%;
      }
   }
}
