.disigner {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;

   &_tabStatus {
      display: flex;
      align-items: center;

      &Text {
         margin-right: 15px;
      }
   }
}
