@import "../../../styles/colors.scss";

.new--researches {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  &--card {
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &--subtitle {
    font-size: 16px;
    line-height: 22px;
    color: $non-grey;
  }
  &--form {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;

    gap: 15px;

    width: 100%;

    list-style: none;

    margin: 0px;
    padding: 0px;
    &--item {
      position: relative;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      flex-direction: row;

      gap: 5px;

      .item--text {
        min-width: 200px;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &--multiinput {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      input {
        height: 35px !important;
      }
    }
  }

  &--buttons {
    padding-top: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &--button {
    height: 30px;
    padding: 0px 15px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    font-size: 14px;
  }

  &--publish {
    background: #019467;
    color: #ffffff;
  }

  &--delete {
    background: #ff837b;
    color: #ffffff;
  }
}

.dates {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
