.mh- {
    &select {
        position: absolute;
        background: #F8FAFC;
        overflow: hidden;
        border-radius: 4px;
        width: inherit;
        &__outline {
            background: #ffffff;
            border: 1px solid #E0E0E0;
        }
        &Wrapper {
            height: 37px;
            position: relative;
            z-index: 2;
        }
        &_input {
            cursor: pointer;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 10px 0px 10px;

            img {
                margin-left: auto;
            }
        }
        &__outline>&_list {
            border-top: 1px solid #E0E0E0;
        }
        &_list {
            list-style: none;
            display: none;
            padding: 0px;
        }
        &_item {
            cursor: pointer !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 13px 6px 16px;
            color: #757575;
            font-size: 14px;
            line-height: 14px;
            &__current {
                pointer-events: none;
                background: rgba(151, 204, 255, 0.14);
                cursor: default !important;
            }
        }
        &:not(&__disabled) &_list__shown {
            display: block;
        }
    }
}

.mh-selectFix_input:hover+.mh-selectFix_list,
.mh-selectFix_list:hover {
    display: block;
}