.mh- {
  &modal {
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    padding: 15px;
    border-radius: 10px;
    max-width: 96vw;
    &_header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &Text {
        font-size: 16px;
        color: #666666;
        margin-right: 10px;
        line-height: 16px;
      }
    }
    &_close {
      border: none;
      background: none;
    }
    &Background {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
      z-index: 10;
    }
    &Wrapper {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }
  }
}
