.aside {
  height: 100vh;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.aside--title {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
}
span.menu-toggle-btn {
  display: flex;
  font-size: 0.9em;
  grid-column-gap: 10px;
  align-items: center;
  background: #eee;
  padding: 6px 10px;
  border-radius: 5px;
}
.logo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  &--icon {
    width: 29px;
    height: 39px;
  }
}

.logo--text {
  font-size: 18px;
  line-height: 25px;
}

.offcanvas-navbar {
  width: 100%;
}

.navigation {
  list-style: none;
}

.navigation--list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  padding: 0px;
  margin: 0px;
  gap: 15px;
}

.aside--submenu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 15px;
}

.astions {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
}

.actions--title {
  font-size: 15px;
  line-height: 20px;
}

.actions--row {
  width: 100%;
  height: auto;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.actions--exit {
  width: 100%;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-decoration: none;
}

.icon--exit {
  width: 15px;
  height: 15px;
}

.text--exit {
  font-size: 14px;
  line-height: 19px;
  color: #000;
  text-decoration: none;
  font-weight: 600;
}
.popover--text {
  font-weight: 600;
  font-size: 1em;
  display: flex;
  grid-column-gap: 10px;
}
// @media (max-width: 576px) {

//     img.popover--head--arrow {
//         display: none;
//     }
//     ul.navigation--list .popover--text {
//         font-weight: 600;
//         font-size:80%;
//         flex-direction: column;
//         font-size: 80%;
//         align-items: center;
//         display: flex;
//         grid-column-gap: 10px;
//     }
//     .popover--head--icon {
//         width: 25px;
//         height: 25px;
//     }
//     nav.navigation {

//         position: fixed;
//         left: 0;
//         bottom: 0;
//         background: #fff;
//         width: 100%;
//         box-shadow: 0 0 10px #c4c4c4;
//     }
//     nav.navigation ul {
//         display: grid;
//         grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
//         width: 100%;
//         justify-content: space-between;
//         padding: 0 1em;
//     }
//     nav.navigation ul.popover--sublist {
//         display: none;
//     }
//     .message--count {
//         position: absolute;
//     }
//     li.popover--head {
//         justify-content: center;
//         display: flex;
//         overflow: hidden;
//         word-break: break-all;
//         text-align: center;

//     }
// }
