.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.add-form {
    float: right;
    margin-right: 70px;
}

#date-start {
    display: inline-block;
    width: 45%;
}

#until-expired {
    display: inline-block;
    width: 50%;
    margin-left: 30px;
}

.btn.btn-primary {
    margin-right: 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.page--content {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.page--routs {
    padding-top: 47px;
    width: 100%;
}