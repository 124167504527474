@import "../../../views/messages/index.scss";

.chat- {
   &viewHeader {
      width: 100%;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $white;
      padding: 0px 16px;

      &_avatar {
         width: 40px;
         height: 40px;
         border-radius: 20px;
         margin-right: 8px;
      }

      &_info {
         width: 100%;
      }

      &_searchButton {
         border: none;
         background: none;
         display: flex;
         align-items: center;
         justify-content: center;
         width: 40px;
         height: 40px;
         cursor: pointer;
      }
   }

   &viewUser {
      padding-bottom: 4px;
      margin-bottom: 0px;

      &_name {
         font-size: 16px;
         line-height: 20px;
         font-weight: 700;
         padding-right: 5px;
      }

      &_role {
         font-size: 12px;
         line-height: 20px;
         color: $light-text;
      }
   }

   &viewLastTimeOnline {
      font-size: 14px;
      line-height: 18px;
      color: $light-text;
      margin-bottom: 0px;
   }

   &viewMassages {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      // width: calc(100% - 30px);
      height: calc(100% - 132px);
      overflow-y: auto;
      padding: 15px calc(50% - 360px);
   }

   &viewSendForm {
      width: calc(100% - 30px);
      max-width: 690px;
      height: 56px;
      margin: 0px auto;
      background: $white;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 20px;

      &_attachmentsButton,
      &_sendButton {
         width: 24px;
         height: 24px;
         display: flex;
         align-items: center;
         justify-content: center;
         border: none;
         background: none;
         cursor: pointer;
      }

      &_input {
         margin: 0px 16px;
         border: none;
         background: none;
         width: 100%;
         height: 100%;
         font-size: 16px;
         line-height: 20px;

         &::placeholder {
            color: $light-text;
         }
      }
   }
}
