@import "../../../styles/colors.scss";
.builder--researches {
  position: relative;
  width: 100%;
  min-height: 650px;
  max-height: 700px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 1px;
  &--preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  &--options {
    position: sticky;
    top: 60px;
    right: 0px;
    width: 220px;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 650px;
  }
}

.template {
  position: sticky;
  top: 60px;
  left: 0px;
  width: 160px;
  min-width: 160px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &--title {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: #828282;
    margin: 0px;
    &--preview {
      font-size: 19px;
      line-height: 26px;
      color: #333333;
    }
  }
  &--list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    list-style: none;
    padding: 0px;
    padding-top: 15px;
    margin: 0px;
    &--item {
      cursor: grab;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 6px 10px;
      width: 100%;
      background: #d4e8fd;
      border-radius: 5px;
      &--title {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
      }
      &--image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
      }
    }
  }
  &--button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px 10px;

    width: 130px;
    height: 28px;

    background: $light-text;
    border-radius: 4px;

    font-size: 12px;
    line-height: 20px;

    cursor: pointer;

    margin-left: 10px;
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}

.template--preview {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.btn--text {
  color: $white;
}

.template--options-content {
  width: 100%;
  max-width: 220px;
  align-self: flex-end;
}

.mh- {
  &constructor {
    &Wrapper {
      background: #ffffff;
      border: 1px solid #dfdfdf;
      padding-left: 2px;
      &:not(&__sub):not(:first-child) {
        border-top: none;
      }
      & > .mh-constructorField {
        max-height: 0px;
        opacity: 0;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }
      &__selected {
        border: 1px solid #dfdfdf;
        border-left: 3px solid #00a1a0;
        padding-left: 0px;
      }
      & > .tp {
        margin: -20px 0px;
      }
      &__hover {
        & > .mh-constructorField {
          max-height: 43px;
          opacity: 1;
        }
        & > .tp {
          margin: 0px;
        }
      }
      &__sub {
        margin-left: 10px;
      }
      &__chapter {
        background: none;
        border: none;
        border-left: 1px solid #00000000;
        &:not(:last-child) {
          border-bottom: 1px solid #dfdfdf;
        }
        &.mh-constructorWrapper__selected {
          border-left: 3px solid #00a1a0;
        }
      }
      &__sub {
        margin-bottom: 10px;
      }
    }
    &Field {
      width: auto;
      margin: 10px;
    }
  }
}

.tabs {
  &--actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
