.calculator {
  display: flex;
  #calculate-result {
  }
  .title {
    color: #000;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 15px;
  }
  .form {
  }
  .label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .resultContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fcfcfc;

    .resultText {
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
    .resultButton {
      background-color: #97ccff;
      color: #32414f;
      font-size: 14px;
      border-radius: 5px;
      border: none;
    }
    .copiedButton {
      background-color: #019467;
      color: white;
      border-radius: 5px;
    }
  }
  .alert {
    background-color: #feedee;
    color: #e37278;
    margin-top: 20px;
  }
  .submitButton {
    border-radius: 5px;
  }
  .savedText {
    color: #019467;
    font-size: 14px;
    font-weight: 300;
    margin-left: 15px;
  }
}
