@import "../../../styles/colors.scss";

input[type="file"] {
  display: none;
}

.mh-file {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px 10px;

  width: 111px;
  height: 28px;

  background: $light-text;
  border-radius: 4px;

  font-size: 12px;
  line-height: 20px;

  cursor: pointer;

  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &_list {
    tr {
      border: none;
    }
  }

  &_item {
    margin-right: 5px;
    margin-bottom: 5px;
    td {
      padding-right: 10px;
    }
  }

  &_name {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    width: auto;
    max-width: 175px;
  }

  &_progress {
    width: 100%;
    .progress-bar {
      background-color: $success-green !important;
    }
  }

  &_size {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: $darker-grey;
    width: auto;
    white-space: nowrap;
  }

  &_percentage {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: $darker-grey;
  }

  &_remove {
    border: none;
    background: none;
    height: auto;
  }

  &_messages {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &_message {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    &_success {
      color: $success-green;
    }

    &_error {
      color: $warning-red;
    }
  }
}
