div#createForm-container {
  display: flex;
  grid-column-gap: 1em;
}

div#createForm-container div#page-content {
  flex-grow: 1;
  max-width: 1000px;
}
.topInfo {
  position: sticky;
  top: 79px;
  background: #fff;
  z-index: 1;
  box-shadow: 0 6px 9px #0000000a;
  padding: 10px;
}
.visit-accepted::after {
  content: "● Принято";
  background: #019467;
  padding: 3px;
  display: inline-block;
  margin-left: 3px;
  color: white;
}
.visitTabs {
  background: #fff;
}
.visitTabs.sticky {
  top: 45px;
  position: sticky;
  z-index: 1;
}
.visit-draft::after {
  content: "● Черновик";
  background: #ffe5a1;
  padding: 3px;
  display: inline-block;
  margin-left: 3px;
}

.answer-wrapper select {
  width: max-content;
}
img.conditions-icon {
  width: 15px;
  height: 15px;
}
.invalid-tooltip {
  z-index: 5;
}
@media (max-width: 575.98px) {
  .answer-wrapper {
    padding: 15px;
  }
  .chapter {
    padding: 1em !important;
  }
  div#form-viewer {
    flex-direction: column-reverse;
    padding-bottom: 143px;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
