@import "../../styles/colors.scss";

.user {
   display: flex;
   align-items: center;
   justify-content: flex-start;

   gap: 15px;
   .user--avatar--image {
      object-fit: cover;
      border-radius: 100%;
   }
   &--name,
   &--name:hover {
      text-decoration: none;

      font-size: 15px;
      line-height: 20px;

      color: $text;
   }
   &--popup {
      position: relative;
   }
}

.user--avatar,
.user--avatar--image {
   position: relative;
   width: 33px;
   height: 33px;
}

.user--avatar::after {
   position: absolute;
   right: 0px;
   bottom: 0px;
   border-radius: 8px;
   width: 8px;
   height: 8px;
   content: "";
   border: 1px solid #ffffff;
   background-color: $success-green;
}

.user--icon--arrow {
   transform: rotate(90deg);
   cursor: pointer;
}
