@import "../../styles/colors.scss";
.popover--head {
    width: 100%;
    list-style: none;
}

.popover--head {
    &--icon {
        width: 20px;
        height: 20px;
    }
    &--arrow {
        transition: transform 0.3s ease-in-out;
    }
}

.popover--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    padding-bottom: 10px;
    div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}

.popover--text {
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    color: $text !important;
}

.popover {
    &--sublist {
        display: flex;
        flex-direction: column;
        max-height: 250px;
        overflow: hidden;
        transition: max-height 0.4s ease-in-out;
        gap: 10px;
        padding-left: 0px;
        &__hidden {
            max-height: 0px;
        }
    }
}

.message--count {
   width: 22px;
   height: 22px;
    position: relative;
   border-radius: 20px;
   background-color: #78e378;
}

.message--count::before {
    content: attr(value);
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: normal;
    color: white;
    margin-top: 1px;
    position: absolute;
    display: block;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

@media (max-width: 576px) {
    .popover--wrapper {
        user-select: auto;
    }
}
