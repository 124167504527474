@import "../../../views/messages/index.scss";

.chat- {
   &searcInput {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0px 19px;
      border-radius: 20px;
      background: $light-grey;

      &_field {
         width: 100%;
         font-size: 16px;
         line-height: 16px;
         border: none;
         background: none;
         margin-left: 19px;

         &::placeholder {
            color: $light-text;
         }
      }
   }
}
