@import "../../styles/variable";
.rdrDateDisplayWrapper,
.rdrDefinedRangesWrapper {
    display: none;
}

.rdrDateRangeWrapper {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
}

.rdrDateRangePickerWrapper {
    position: absolute;
    top: 34px;
    right: calc(-624px /2);
    z-index: 10;
}


/* .rdrMonth button span {
  border-radius: 0!important;
} */

.rdrDayToday .rdrDayNumber span:after {
    background: $AccentColor;
}

.date-picker {
    position: relative;
    &__header {
        display: inline-flex;
        border: 1px solid #F6F6F6;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
        padding: 6px 0 6px 15px;
        cursor: pointer;
        position: relative;
        background-color: #ffffff;
        &--icon {
            display: inline-flex;
            align-items: center;
            padding: 0 15px;
        }
    }
    &__head {
        font-family: $font;
        font-weight: 400;
        font-size: 13px;
        color: $Text;
    }
}

.date-picker-overlay {
    background-color: rgba(70, 70, 70, 0.15);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
}