@import "../../styles/colors.scss";

.header,
.header--menu {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.header {
   height: 47px;
   width: calc(100% - 223px);

   padding: 0px 20px;

   position: fixed;

   background-color: $white;

   z-index: 10;
}

.header--menu {
   gap: 15px;
}

.header--breadcrumbs {
   font-size: 14px;
   line-height: 19px;
}
