@import "../../../styles/colors.scss";

@import "../../../styles/colors.scss";

.patientcode--researches {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   justify-content: flex-start;
   gap: 50px;
   padding-bottom: 20px;
   &--card {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
   }
   &--form {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      flex-direction: column;

      gap: 15px;

      min-width: 100%;

      list-style: none;

      margin: 0px;
      padding: 0px;
      &--item {
         min-width: 100%;

         display: flex;
         justify-content: flex-start;
         align-items: stretch;
         flex-wrap: nowrap;
         flex-direction: row;

         gap: 5px;

         .item--text {
            min-width: 200px;
            font-size: 14px;
            line-height: 20px;
         }

         &-horizontal {
            flex-direction: column;
            
            .item--text {
               display: flex;
               justify-content: space-between;
               align-items: center;

               &-link {
                  color: #019467;
                  cursor: pointer;
               }
            }
         }
      }
   }
}
