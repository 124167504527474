.wrapper {
  margin-top: 15px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.buttonsWrapper {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.button {
  height: 30px;
  padding: 0 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  border: none;
  background: #019467;
  gap: 10px;
  &.delete {
    background: #ff837b;
  }
  &.btn_condition {
    background: transparent;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    width: fit-content;
  }
}

.answer {
  width: 300px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 10px;
  display: block;
}

.condition {
  display: flex;
  gap: 0.8em;
  min-width: 0;
  justify-content: space-between;
  &List {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &visitnumber {
    /* position: absolute;
    right: 10px; */
    background: #eee;
    padding: 0 5px;
    font-size: 90%;
    color: #6d6d6d;
  }
}

.select {
  &Big {
    width: 350px;
  }
  &condwrapper {
    position: relative;
    overflow: unset;
    &__list {
      position: absolute;
      top: 100%;
      left: 0;
      background: #f8fafc;
    }
  }
  &Item {
    overflow: hidden;
    max-height: 4rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    line-height: 1.5em;
    border-bottom: 1px solid #d0d0d0;
    padding: 3px 9px;
  }
  &Text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  &:hover {
    z-index: 3;
  }
}

.rangeInput {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  input {
    width: 100px;
    margin-right: 0;
  }
}

.disabled {
  color: #757575;
}

.deleteCondition {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  background-color: #ff837b;
}

.emptyDelete {
  width: 26px;
  height: 26px;
}
