@import "./styles/colors.scss";
* {
    font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ml-10 {
    margin-left: 10px !important;
}

.dospace {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.woot-widget-bubble{
    bottom:60px!important;
}
/* .btncontrol .btn.btn-success {
    border-radius: unset;
    background-color: #e7e4e4;
    border-radius: 5px !important;
    color: #333;
    border: 1px solid #eee!important;
}

.btncontrol .btn.btn-primary {
    border-radius: unset;
    background-color: #fff;
    border-radius: 5px !important;
    color: #333;
    border: none;
} */

.btncontrol .btn.btn-primary:hover {
    color: white !important;
    margin-right: 10px;
    background-color: #019467;
}

.advanced_fields input {
    place-content: flex-end !important;
}