/* .login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    text-align: center;
    position: absolute;
    left: 30%;
    right: 30%;
    margin: auto;
    margin-top: 10%;
    padding: 50px;
    background-color: rgb(1 148 103 / 20%);

    border-radius: 10px;
    box-sizing: content-box;
    border: 1px solid rgb(1 148 103);
} */

.login-wrapper label {
  width: 100%;
  margin-bottom: 0px;
}

.login-wrapper input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 6px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
}

.login-wrapper button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  margin: auto;
}

input[type="password"] {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  background: #f8fafc;
  border-radius: 4px;
  border: none;
}

.restore-password {
  text-decoration: none;
  text-align: right;
  font-size: 90%;
  color: #2d9cdb;
  cursor: pointer;
}

.form-input {
  height: 32px;
}

div#password-input--with-icon {
  position: relative;
  margin-bottom: 5px;
}

.login-wrapper {
  flex-grow: 1;
}

span.show-password {
  position: absolute;
  right: 5px;
  top: 50%;
  width: 20px;
  align-items: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  transform: translateY(-50%);
}

span.show-password img {
  width: 18px;
}

label p {
  margin-bottom: 5px;
}

form#autorize {
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
}

div#login-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fcfcfc;
}

div#login-form {
  display: flex;
  flex-direction: column;
  width: 40%;
}

h1 {
  font-size: 1.5em;
  font-weight: 700;
}

.errors {
  padding: 10px;
  background: #ffdede;
  color: #db6565;
  border-left: 2px solid #d66f6f;
  font-size: 90%;
}

form#autorize .button {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.login-card--wrapper {
  display: flex;
  grid-column-gap: 2em;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 1em;
  max-width: 50%;
}

.reset-password-button {
  margin-top: 20px;
  margin-bottom: 24px;
}

.reset-password-button button {
  width: 100%;
  text-align: center;
  display: block;
}

button.reset-password-to-login {
  margin: 24px 0 0 0;
  background: #edf4fb;
  height: 38px;
  border: none;
  border-radius: 19px;
  padding: 12px 20px;
}

.reset-password-to-login .to-login-ico {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.use-term {
  font-size: 12px;
}

input.form-input-reset {
  padding-left: 12px;
}

@media (max-width: 575.98px) {
  div#login-card {
    height: auto;
  }
  .login-card--wrapper {
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: center;
  }
  div#login-form {
    width: auto;
  }
  .login-wrapper {
    flex-grow: 0;
    padding-top: 1em;
    width: 100%;
  }
  form#autorize input,
  form#autorize .button {
    height: 40px;
  }
  img.logo-bg {
    width: 200px;
  }
}
