.error {
   width: 100%;
   height: 100%;

   display: flex;
   align-items: center;
   justify-content: center;
}

.error--wrapper {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
}

.error--code--title {
   font-size: 90pt;
}

.error--decription {
   width: 320px;
}

.error--decription-text {
   font-size: 20px;
   line-height: 27px;
}
