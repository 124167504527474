.table-placeholder {
    padding-right: 100px;
}

.table-placeholder_filters {
    height: 26px;
}

.table-placeholder_tabs {
    height: 20px;
}

.table-placeholder_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
}

.table-placeholder_header {
    margin-bottom: 2px;
}