.mh-{
    &constructorField {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
    
        width: 100%;
        height: 43px;
    
        background: rgba(151, 204, 255, 0.19);
        border: 1px dashed #00A1A0;
        color: #00A1A0;
        font-size: 12px;
        border-radius: 10px;
        margin: 10px 0px;
    }
}