@import "../../../styles/colors.scss";
.tabs {
    padding-top: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &--bar {
        &--control {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            display: flex;
            align-items: center;
            background: #ffffff;
            z-index: 1;
            &Button {
                border: none;
                background: none;
                &__right {
                    transform: rotate(180deg) translateY(-1px);
                }
            }
        }
    }
}

.tabs--nav {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 0;
    border-bottom: none;
    &--menu {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 30px;
        margin-right: 15px;
        border-bottom: 1px solid #DDDDDD;
        &--track {
            transition: transform 0.3s ease-in-out;
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
        }
    }
}

.tabs--btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    text-decoration: none;
    color: $text;
    text-align: left;
    border: none;
    cursor: pointer;
    width: 130px;
    font-size: 14px;
    line-height: 20px;
    &Text {
        padding: 4px 21px 4px 6px;
    }
    &:not(:last-child) {
        margin-right: 14px;
    }
}

.tabs--btn-active {
    cursor: default;
    border-bottom: 2px solid $text;
}

.tabs--btn {
    img {
        opacity: 0;
    }
}

.tabs--btn:hover {
    img {
        opacity: 1;
    }
}

.tabs--content {
    display: flex;
    max-height: 800px;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    padding-top: 15px;
    ol:not(.template--list--item) {
        /* убираем стандартную нумерацию */
        list-style: none;
        /* Идентифицируем счетчик и даем ему имя li. Значение счетчика не указано - по умолчанию оно равно 0 */
        counter-reset: li;
    }
    li:not(.template--list--item) {
        position: relative;
        cursor: grab;
    }
    li:not(.template--list--item):hover {
        background: rgba(151, 204, 255, 0.14);
    }
}

.tabs--pane {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: #F7F7F7;
    width: 100%;
}

.tabs--btn--new {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 15px;
    gap: 10px;
    width: 114px;
    height: 30px;
    /* Accent color */
    background: #019467;
    border: 1px solid #00a1a0;
    border-radius: 20px;
}