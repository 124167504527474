.mh- {
    &constructor {
        &_number {
            background: #ffffff;
            &Area {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
            &Input {
                appearance: none;
                padding: 0px 12px;
                width: 100px;
                height: 32px;
                border: 1px solid #E0E0E0;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                transition: border-color .3s ease-out;
                &.inValid {
                    border-color: #842029;
                }
            }
            &Unit {
                font-size: 14px;
                line-height: 14px;
                color: #757575
            }
            &Limit {
                color: #757575;
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
}