.container {
  border-radius: 10px;
}

.header {
  border-bottom: none;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.body {
  font-size: 14px;
}

.addSelectorButton {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 7px;
  background-color: white;
}

.multiInput {
  margin-top: 8px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  input {
    height: 35px !important;
  }
}

.closeButton {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background: rgba(255, 131, 123, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.buttons {
  border-top: none;
  display: flex;
  justify-content: flex-start;
  button {
    height: 30px;
    font-size: 14px;
  }
  .danger {
    border-radius: 19px;
    background: #ff837b;
    border-color: #ff837b;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  img {
    width: 15px;
    fill: #fff;
    height: 15px;
  }
}
