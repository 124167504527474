@import "../../styles/variable";

.selector {
  position: relative;

  &__header{
    display: inline-flex;
    border: 1px solid #F6F6F6;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 5px 15px;
    cursor: pointer;
    position: relative;
    background-color: #ffffff;

    &--icon{
      display: inline-block;
      padding: 0 15px;
    }
  }

  &__head{
    font-family: $font;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $Text;
  }
}

.selector-overlay {
  background-color: rgba(70, 70, 70, 0.15);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.selector-search {
  position: absolute;
  width: 335px;
  top:34px;
  z-index: 10;
  background-color: $LightBlue;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__header {
    padding: $padding-10;
    width: 100%;
  }

  &__wrapper {
    background-color: #ffffff;
    border: 1px solid #F6F6F6;
    border-radius: 8px;
    padding: 8px 18px;
  }

  &__input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding-left: 10px;
  }

  &__list {
    padding: 10px 0;
    background-color: #ffffff;
    max-height: 200px;
    overflow-x: auto;

    &::-webkit-scrollbar{
      width: 3px;
    }

    &::-webkit-scrollbar-track{
      background-color: #dcdcdc;
    }

    &::-webkit-scrollbar-thumb{
      background-color: $AccentColor;
    }
  }

  &__item{
    display: flex;
    align-items: center;
    padding: $padding-10;

    &:hover{
      background-color: $LightBlue;
      cursor: pointer;
    }
  }

  &__text{
    font-family: $font;
    font-weight: 300;
    font-size: 14px;
    color: $Text;
    display: inline-block;
    margin-left: 8px;
  }

  &__footer{
    padding: 15px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__counter {
    font-family: $font;
    font-weight: 400;
    font-size: 12px;
    color:$NotActiveGrey;
  }
}
