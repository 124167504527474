.online-button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 5px!important;
  background-color: #C3FFDD;
  margin-top: 11px;
  width: 67px;
  height: 25px;
}

.secondary_font {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.change_email {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  cursor: pointer;
  text-decoration-line: underline;

  color: #019467;
}

.hover_image {
  border-radius: 50px;
    height: 107px;
    width: 107px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0000003b;
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
}

.hover_image:hover {
  opacity: 1;
}

.user-page-info {
  &-title {
    &-name {
      font-weight: 700;
      font-size: 24px;
      color: #333333;
      // margin-bottom: 8px;
    }
    &-id {
      font-weight: 400;
      font-size: 14px;
      color: #828282;
    }
  }
  &-item {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}

.profile-button-group {
  .change-password {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    &-link {
      text-decoration-line: underline;
    }
  }
}