@import "../../../styles/colors.scss";

input[type="teatarea"],
.mnh--teatarea {
   width: 100%;
   min-height: 61px;
   max-height: 320px;

   padding: 6px 12px;

   background: $white;

   border: 1px solid #E0E0E0;
   border-radius: 4px;
}
