.mh-{
    &constructor {
        &_checkBoxes {
            background: #ffffff;
            display: flex;
            flex-direction: column;

            &__row {
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
}